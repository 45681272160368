@import ../variables

@import url(https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,500i,600,600i,700,700i)

html
  font-family: $font-family

\:global
  body.application
    background-color: #F0F2F5

  body.devise
    background-color: #FFFFFF

  .devise-component-wrapper
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: inherit
    @include breakpoint(mobile, only)
      justify-content: flex-start
      margin-top: 50px
      padding: 0 20px
          
  body.profile
    background-color: #FFFFFF
    > div
        height: 100%
  
  .error
    color: #ff4d4f
  
  .new-password-wrapper
    width: 400px
    display: flex
    flex-direction: column
    @include breakpoint(mobile, only)
      width: 100% 
  .new-password
    position: relative
    display: flex
    width: 100%
  .password-form-wrapper
    display: flex
    > div
      flex-direction: column
  .password-tips
    position: absolute
    right: -400px
    @include breakpoint(mobile, only)
      bottom: -200px
      left: 0
      