.formWrapper
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  padding-top: 10px
  padding-bottom: 90px
  \:global
    .ant-collapse-header
      padding-left: 0px !important
      font-size: 20px
      font-weight: 600
    .ant-card-bordered
      border: none
    .ant-card-body
      padding-left: 0
    .ant-collapse-content-box
      padding-left: 36px
      > div
        padding-left: 0
    .ant-form-item-explain-error
      color: #ff4d4f !important


.footer
  height: 80px
  display: flex
  width: 100%
  align-items: center
  box-shadow: 0px -6px 9px 0px #cacaca
  background-color: #FFFFFF
  position: fixed
  z-index: 9
  background: #FFFFFF
  bottom: 0
  left: 0

.actionWrapper
  display: flex
  width: 100%
  justify-content: flex-end
  > div
    margin-left: 10px
    margin-right: 10px

.logoWrapper
  display: flex
  > div 
    display: flex
    flex: 1 1 auto

.layoutWrapper
  display: flex
  flex-direction: row
  height: 100%
  width: 100%
  background-color: #ffffff

.sidebar
  padding-top: 80px
  border-right: 1px solid
  box-shadow: 0 0 9px 4px #cacaca
  height: 100%
  display: flex
  position: fixed
  height: 100vh
  width: 80px
  z-index: 10

.headerWrapper
  height: 80px
  display: flex
  width: 100%
  align-items: center
  margin-bottom: 10px
  box-shadow: 6px 0 9px 4px #cacaca
  background-color: #FFFFFF
  position: fixed
  z-index: 9
  background: #FFFFFF
  left: 0

.header
  display: flex
  flex-direction: row
  font-size: 18px
  font-weight: 600
  width: 100%
  padding-left: 15px
  align-items: center

  > h3
      margin-bottom: 0px

.contentWrapper
  flex-direction: column
  background: transparent
  min-height: 280px
  width: 100%
  padding-left: 80px
  position: relative

.passwordPanelWrapper
  display: flex
  > form
    flex: 1 1 auto
  > div
    margin-left: 20px
    display: flex
    flex-direction: column