.container
  color: #343434
  &:not(.inline)
    display: flex
    flex: 1 1 auto
    justify-content: flex-end
  &.inline
    display: inline-block
    margin-left: 5px

