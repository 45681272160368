.contentWrapper
    flex-direction: column
    background: transparent
    min-height: 280px
    width: 100%
    position: absolute

.antLayoutWrapper
    display: flex
    background: #F0F2F5
    height: 100%

.layoutWrapper
    display: flex
    flex-direction: row
    height: 100%
    width: 100%

.headerWrapper
    height: 80px
    display: flex
    width: 100%
    align-items: center
    margin-bottom: 10px
    box-shadow: 6px 0 9px 4px #cacaca
    background-color: #FFFFFF
    position: fixed
    z-index: 9
    background: #FFFFFF
    left: 0
