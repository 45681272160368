.impersonateWrapper
 &:hover
    cursor: pointer

.actionsWrapper
    width: 100%
    display: flex

.exportWrapper
    display: flex
    justify-content: flex-end
    width: 100%
    margin-right: 14px

.selectedElementsWrapper
    width: 100%
    text-align: center
    margin-bottom: 10px !important

.searchForm
    margin: 15px 0
    padding: 14px
    border-radius: 7px
    background-color: #fafafa
    border: 1px solid #e8e8e8

.searchButton
    width: 100%
    display: flex
    justify-content: flex-end

.appHeaderWrapper
    display: flex
    align-items: center
    > img
        width: 25px
        margin-right: 5px

.footerAction
    display: flex

    > div
        justify-content: flex-end
        width: 100%

.activeWrapper
    position: relative
    display: flex
    align-content: center
    justify-content: center

.circleOutlined
    position: absolute
    top: -5px
    left: 50%

.userAppStatutsWrapper
    display: flex
    justify-content: center
    align-items: center
    > div
        flex-direction: column
        font-size: 13px

.infoWrapper
    display: flex
    flex-direction: column
    > div
        text-align: left

.tooltipWrapper
    display: flex
    flex-direction: column

.alertUserSelection
    margin-bottom: 15px
    > div
        display: flex
        justify-content: center
        &:hover
            cursor: pointer

.alertEditingWarning
    margin-bottom: 15px
    > div
        display: flex
        justify-content: flex-start
        > ul
            margin-bottom: 0

.ant-select 
    width: 100%

.table
    tbody > tr
        cursor: pointer