@import "./variables"
@import "./layouts/devise"
@import "./layouts/application"

\:global
  .ant-form-item-explain-error
    color: #ff4d4f !important

  .ant-menu-submenu.ant-menu-submenu-popup
    ul.ant-menu
      .ant-menu-item
        display: flex
        align-items: center
  .ant-select
    width: 100%

  .ant-page-header.ant-page-header-compact
    padding-left: 0

  .dotted-underlined
    border-bottom: 1px dotted $jet

  .cursor-pointer 
    cursor: pointer

  .mb-4
    margin-bottom: 1rem

  .mb-2
    margin-bottom: 0.5rem
  
  .shadow
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)
  
  .bg-white
    background-color: #fff

  .text-warning
    color: #faad14

  .text-danger
    color: #ff4d4f