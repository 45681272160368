.fileStateWrapper
  display: flex
  padding: 5px 0

.iconRunningFile
  color: #e6e6e6
  font-size: 33px !important
  @media (max-width: 767px)
    display: none !important
.popoverContent
  width: 500px
  max-height: 500px
  overflow-y: scroll

.labelWrapper
  margin-left: -15px
  display: flex
  flex-direction: column
  justify-content: center

.iconWrapper
  align-items: center

.downloadWrapper
  &:hover
    cursor: pointer

.popoverWrapper
  position: relative
  display: flex
  align-items: center

  &:hover
    cursor: pointer

.notificationDot
  position: absolute
  top: 10px
  right: 0
  border-radius: 50%
  font-size: small
  height: 15px
  width: 15px
  text-align: center
  @media (max-width: 767px)
    display: none !important

$icon-size: 25px
$wrapper-size: 40px
$active-color: #343434

.wrapper
  height: $wrapper-size
  width: $wrapper-size
  display: flex
  align-items: center
  justify-content: center
  &:hover
    cursor: pointer
    .icon
      color: $active-color
  .icon
    height: $icon-size
    font-size: $icon-size
    color: #e6e6e6
    transition: color .1s linear

    &.active
      color: $active-color

.popoverTitle
  display: flex
  a
    flex: none
    margin-left: auto

.badge
  \:global
    .ant-badge-count, .current
      line-height: 20px !important
    .ant-badge-dot
      width: 15px
      height: 15px
