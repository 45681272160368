.headerWrapper
  display: flex
  align-items: center
  \:global 
    .actions
      flex: 1 1 auto
      display: flex
      justify-content: flex-end

.portalLoader
  width: 100%
  height: 100%
  display: flex
  align-content: center
  justify-content: center

.block
  text-align: center
  width: 250px
  font-size: 27px
  line-height: 27px
  font-weight: bold
  border-radius: 0.5rem
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center
  padding: 15px 15px
    
.cardBlock
  text-align: center
  width: 200px
  > div 
    font-size: 20px
    font-weight: bold
    border-radius: 0.5rem
    box-shadow: 0 0 7px 1px #d2d2d2
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: center

.cardBody
  overflow: hidden

.cardImage
  width: 100%
  height: 100%

.blockTitle
  font-size: 20px
  font-weight: 500
  color: #BEBEBE
  margin-top: 5px
  margin-bottom: 10px

.blockLabel
  font-size: x-small
  font-weight: normal

.portalList
  list-style: none
  padding-left: 35px

.quickAccessList
  list-style: none
  padding-left: 20px

.dashboardWrapper
  display: flex
  flex-direction: column

.headerWrapper
  display: flex
  width: 100%

.actionWrapper
  display: flex
  flex: 1 1 auto
  justify-content: flex-end
  align-items: center
  > h3
    margin-bottom: 0
  
.tableRow
  background-color: #f0f2f5

.table
  \:global
    .ant-table
      box-shadow: unset !important

.imageWrapperBackground
  position: relative
  background: white
  width: 34px
  height: 34px
  border-radius: 20px
  border: 5px solid #BEBEBE
  > img
    position: absolute
    top: -3px
    left: -3px
