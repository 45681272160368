.wrapper
  border-radius: 4px
  border: none
.loginWrapper
  width: auto
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  >
    font-weight: 500

.linkWrapper
  display: flex
  justify-content: flex-end
  align-items: center
  margin-bottom: 24px
