.actionsWrapper
  display: flex
  justify-content: flex-end
  align-items: center
  width: 100%
  margin-right: 14px

.groupWrapper
  display: flex

.tableWrapper
  \:global
    .ant-table-cell-row-hover
      background: #ebebeb !important
    .permissionGroupRow
      background: #ebebeb
      > td
        border-right: none !important
