.footerAction
  display: flex
  > div 
    justify-content: flex-end
    width: 100%
  
.result
  \:global
    .ant-result-title
      font-size: 22px !important
    .ant-result-extra
      margin-top: 10px