.errorMessageWrapper
    display: flex
    flex: 1
    justify-content: center
    flex-direction: column
    align-items: center
    height: 100%

.imageAccessDenied
    width: 500px
    margin-right: 200px

.catchPhrase
    text-align: center
    font-size: 50px
    margin-top: -30px
    color: #737878
    font-weight: 300

.subCatchPhrase
    text-align: center
    font-size: 25px
    font-weight: 3001
    color: #737878
    margin: 0

.rootLink
    text-align: center
    font-size: 25px
    font-weight: 300
    color: #737878
    margin: 0
