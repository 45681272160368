.userWizardWrapper
    width: 100%
    display: flex
    flex-direction: column
    \:global
        .ant-collapse-header
            padding-left: 0px !important
            font-size: 20px
            font-weight: 600
        .ant-card-body
            .ant-form-item
                margin-bottom: 0px
        .ant-card-bordered
            background-color: #F0F2F5

.actionWrapper
    width: 100%
    display: flex
    justify-content: flex-end

.breadcrumbWrapper
    text-align: center
    background-color: #F0F2F5
    line-height: 35px
    margin-bottom: 15px
    border-radius: 4px

.accountWrapper
    padding-left: 10px
    display: flex
    width: 100%

.accountForm
    width: 80%

.avatarForm
    width: 30%
    padding-left: 20px

.portalsWrapper
    display: flex

.portalWrapper
    padding: 20px 15px
    max-width: 200px
    margin-bottom: 8px
    display: flex
    flex-direction: column
    justify-content: center
    border: 2px solid
    border-radius: 8px
    align-items: center
    border-color: #d5d5d5
    &.checked
        border-color: #49f97f !important
    &:hover
        cursor: pointer
        background-color: #fdfdfd
        border-color: #e5e5e5 !important
        &.checked
            border-color: darken(#49f97f, 5%) !important
        transition: 0.2s
    > img
        height: 50px
    > div
        margin-top: 10px
        display: flex
        justify-content: center
        word-break: break-word

.disabledPortalWrapper
    padding: 20px 15px
    width: 200px
    margin-right: 20px
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    border: 2px solid
    border-radius: 8px
    align-items: center
    &::after
        content: " "
        margin-top: 0px
        position: absolute
        top: 0
        left: 0
        border-radius: 6px
        width: 100%
        height: 100%
        background-color: #f3f3f3ba
    &:hover
        cursor: not-allowed
    > img
        height: 50px
    > div
        margin-top: 10px
        display: flex
        justify-content: center
        word-break: break-word

.existingUserWrapper
    &:hover
        cursor: pointer

.submitWrapper
    display: flex
    justify-content: flex-end
    flex: 1 1 auto

.hierarchyFilterAppWrapper
    padding-left: 10px
        
    > div
        background-color: #F0F2F5

.checkboxesWrapper
    padding-left: 10px
    \:global
        .ant-form-item
            margin-bottom: 0px
        .ant-checkbox-group
            > div 
                flex-direction: column

.applicationRolesWrapper
    display: flex
    flex-direction: column
    padding-left: 10px

.applicationSettingsWrapper
    display: flex
    padding-left: 10px

.publicationLevelFormsWrapper
    display: flex
    flex-direction: column


.publicationLevelFormWrapper
    display: flex
    align-items: center
    min-width: 200px
    > form
        margin-left: 10px
        flex: 1 1 auto
        justify-content: flex-end

.notificationsWrapper
    \:global
        .ant-radio-group
            display: flex !important
            flex-direction: column !important
    display: flex
    flex-direction: column
    width: 100%

.appSettingWrapper
    display: flex
    flex-direction: column

.collapseWrapper
    margin-top: 15px

.appTitle
    font-weight: 600
    font-size: 16px

.streamTitleWrapper
    display: flex
    flex: 1 1 auto
    justify-content: flex-start

.loaderWrapper
    width: 100%
    min-height: 600px
    display: flex
    align-items: center
    justify-content: center