.container
    margin: 15px 0
    padding: 10px 0
    border-radius: 7px
    background-color: #fafafa
    border: 1px solid #d73a49

    \:global
        .ant-list-item
            padding: 14px
