.synchronizeWrapper
    display: flex
    justify-content: flex-end
    flex: 1 1 auto

.errorsMessageWrapper
    display: flex
    flex-direction: column

.importerFooter
    display: flex
    justify-content: flex-end