\:global
  .ant-table
    box-shadow: -2px 0 13px 0 #E4E5F1 !important
    
.notificationTable
  \:global
    .ant-table-row
      &:hover
        .inactiveNotificationTableCell  
          background-color: #f1f1f1
        .selectedTableCell
          background-color: #7EC2E9
        .headerTableCell
          background-color: #FFFFFF
        .firstHeaderTableCell
          background-color: #FFFFFF
        .firstTableCell
          background-color: #FFFFFF
    .notificationTableCell
      text-align: center
      padding: 0 !important
      border-right: solid 1px #dddddd
    .inactiveNotificationTableCell  
      text-align: center
      padding: 0 !important
      border-right: solid 1px #dddddd
      background-color: #f1f1f1
    .selectedTableCell
      text-align: center
      padding: 0 !important
      border-right: solid 1px #dddddd
      background-color: #7EC2E9
    .headerTableCell
      text-align: center
      background-color: #FFFFFF
      border-right: solid 1px #dddddd
    .firstHeaderTableCell
      background-color: #FFFFFF
      border-bottom: 0px
      border-right: solid 1px #dddddd
    .firstTableCell
      background-color: #FFFFFF
      border-bottom: 0px
      font-weight: 500
      border-right: solid 1px #dddddd

.radioWrapper
  margin-left: 10px !important
  \:global
    .ant-radio-wrapper
      white-space: break-spaces !important