.header
  display: flex
  flex-direction: row
  font-size: 18px
  font-weight: 600
  width: 100%
  padding-left: 15px
  align-items: center

  > h3
      margin-bottom: 0px

.userAction
  justify-content: flex-end
  width: 100%
  display: flex
  margin-right: 15px

.userName
  font-size: 14px
  font-weight: 400

.userAvatar
  width: 40px
  height: 40px
  border-radius: 50%
  margin-right: 15px

  @media (max-width: 576px)
    margin-right: 5px

.userDropdown
  display: flex
  align-items: center

.tenant
  display: flex
  max-height: 80px
  &:hover
    cursor: pointer