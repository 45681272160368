.actionsWrapper
  width: 100%
  display: flex

.exportWrapper
  display: flex
  justify-content: flex-end
  width: 100%
  margin-right: 14px

.selectedElementsWrapper
  width: 100%
  text-align: center
  margin-bottom: 10px !important

.searchForm
  margin: 15px 0
  padding: 14px
  border-radius: 7px
  background-color: #fafafa
  border: 1px solid #e8e8e8

.searchButton
  width: 100%
  display: flex
  justify-content: flex-end

.footerAction
  display: flex
  > div 
    justify-content: flex-end
    width: 100%