
.cardBlock
  width: 200px

  &.active
    cursor: pointer
    &:hover
      > div
        background: #f9f9f9 
        &.blockTitle
          color: #000
  > div 
    font-size: 16px
    background: white
    border-radius: 0.5rem
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: center
    margin-bottom: 10px

.cardBody
  overflow: hidden

.cardBlockImageWrapper
  display: flex
  height: 100px
  width: 100%
  align-items: center
  justify-content: center
  background-color: #f9f9f9

.cardImage
  display: block
  max-width: 100%
  max-height: 100%

.blockTitle
  font-weight: 500
  padding: 0.5rem 1rem

.blockFooter
  padding: 0.5rem 1rem
  display: flex
  gap: 1rem

.blockLabel
  font-size: x-small
  font-weight: normal

.blockInfo
  padding-left: 0.5rem
  font-size: 10px

.logoWrapper
  position: absolute
  top: 81px
  left: 20px

.logoContainer
  background-color: white
  padding: 3px
  border-radius: 13px
  box-shadow: 0px 3px 5px #dddddd

.iconLabel
  font-size: 15px
  
.clickableZone
  padding-top: 10px
  border-radius: 8px

.editButtonWrapper
  position: absolute
  z-index: 555
  top: 3px
  left: 168px
  border-radius: 4px
  border: 1px solid transparent
  border-color: #d9d9d9
  width: 20px
  height: 20px
  padding: 0
  margin: 0
  display: flex
  justify-content: center
  align-items: center
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%)
  &:hover
    cursor: pointer