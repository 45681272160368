.siteWrapper
    width: 100%
    display: flex
    align-items: center

.siteListWrapper
    box-shadow: 0 0 9px 4px #e8e8e8

.actionsWrapper
  display: flex
  justify-content: flex-end
  width: 100%
  margin-right: 14px