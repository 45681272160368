.menuWrapper
  border-right: 1px solid
  height: 100%
  display: flex
  flex-direction: column
  position: fixed
  height: 100vh
  width: 250px
  z-index: 10
  \:global
    .ant-menu-submenu-arrow
      color: #ffffff
    .ant-menu-title-content
      min-width: 40px !important

.collapsedWrapper
  display: flex
  justify-content: center
  align-items: center
  z-index: 11
  height: 80px

.collapsedSubIcon
  padding: 0 !important
  margin-left: 31px
  width: 50px !important