.tabsWrapper
    width: 100%

    \:global
        .ant-tabs-nav
            margin-bottom: 0px !important

        .ant-tabs-bar
            margin-bottom: 0 !important

        .ant-tabs-tabpane, .ant-tabs-tabpane-active
            margin-top: 0px !important

.selectWrapper
    width: 400px

.selectLabel
    font-weight: 500
    font-size: 14px

.slugWrapper
    display: flex
    align-items: center
    font-size: 14px
    color: #999999

    & > span
        margin-left: 5px
        color: #999999

.siteLabel
    margin-top: 20px

.modalTitle
    display: flex
    align-items: baseline
    margin-bottom: 15px

.deleteWrapper
    display: flex
    justify-content: flex-end

.hierarchyTreeWrapper
    position: relative
    padding: 14px
    border: 1px solid #e8e8e8
    border-top: 0

.cancelDeleteBackground
    position: absolute
    display: flex
    width: 100%
    height: 100%
    background-color: #d3D3D3
    z-index: 2
    justify-content: center
    align-items: center
    color: #000000
    opacity: 0.5
    margin: -15px

.cancelDelete
    position: absolute
    display: flex
    width: 100%
    height: 100%
    background-color: transparent
    z-index: 3
    justify-content: center
    align-items: center
    margin: -15px
    font-weight: 500
    font-size: 14px
    color: #0645AD

    & > div
        display: flex
        justify-content: center
        align-items: center
        width: 10%
        height: 10%
        background-color: #ffffff

        &:hover
            cursor: pointer

.saveWrapper
    width: 100%
    display: flex
    justify-content: flex-end
    align-items: center
    margin-right: 14px

.hierarchyFormWrapper
  box-shadow: 0 0 9px 4px #e8e8e8

.footerAction
  display: flex
  > div 
    justify-content: flex-end
    width: 100%

.pageHeader
  padding-left: 0px
  padding-right: 0px
  \:global
    .ant-page-header-heading-extra
        display: flex
        align-items: center

.pageHeaderWrapper
    display: flex
    align-items: center

.synchronizeWrapper
    display: flex
    justify-content: flex-end
    flex: 1 1 auto

.appSelectorWrapper
    display: flex
    flex-direction: column
    margin-bottom: 20px
    max-width: 400px