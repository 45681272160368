$body-background: #F5F5F5
$font-url: 'https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,500i,600,600i,700,700i'
$font-name: 'Montserrat' !default
$font-family: $font-name, 'Helvetica Neue', Arial, Helvetica, sans-serif !default
$jet: #343434


$computer-break: 992px
$tablet-break: 768px

@function calculateRem($size)
  $remSize: $size / 14px
  @return #{$remSize}rem

@mixin no-select()
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

@mixin breakpoint($point, $variant: "")
  @if $point == computer
    @media (min-width: $computer-break) 
      @content
  @else if $point == tablet and $variant == ""
    @media (min-width: $tablet-break) 
      @content
  @else if $point == tablet and $variant == "only"
    @media (min-width: $tablet-break) and (max-width: $computer-break - 1)
      @content
  @else if $point == mobile and $variant == "only"
    @media (max-width: $tablet-break - 1) 
      @content
