.hierarchyTitleWrapper
  width: 100%
  display: flex
  align-items: center


.actionsWrapper
  display: flex
  justify-content: flex-end
  width: 100%
  margin-right: 14px